import React, { useState, useEffect } from 'react';
import slide1 from '../images/slide1.jpg';
import slide2 from '../images/slide2.jpg';
import slide3 from '../images/slide3.jpg';
import map from '../images/map.png';
import slide5 from '../images/slide5.jpg';
import slide4 from '../images/slide4.jpg'
import { LiaPeopleCarrySolid } from "react-icons/lia";
import { SiTransmission } from "react-icons/si";
import { IoEyeOutline } from "react-icons/io5";
import  { projects } from './Projects';
import { events } from '../data/eventsData';
import { Link } from 'react-router-dom';


const slides = [
  {
    image: slide1,
    title: 'Talent Discovery and Development Program (TDaD)',
    subtitle: "It involves community outreach aimed at fostering awareness and aiding individuals in uncovering and nurturing their talents.",
  },
  {
    image: slide5,
    title: 'Prince Orji Mentorship Program (POMP)',
    subtitle: 'Platform dedicated to nurturing personal development, fostering leadership skills, and promoting character growth among young people across Africa',
  },
  {
    image: slide3,
    title: 'What Do You Say (WDYS)',
    subtitle: "Solicit ideas and insights from young individuals aged 9-15 regarding Africa's current state and suggestions for instigating meaningful change in the continent.",
  },
  {
    image: slide4,
    title: 'Education for the Underserved (E4U)',
    subtitle: 'Enrolling marginalized and underserved children residing in rural areas, slums, or impoverished educational environments into schools.',
  },
];

function Home() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const latestEvents = events.slice(-10).reverse();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % latestEvents.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [latestEvents.length]);

  return (
    <main className='bg-gray-100'>
      <div className="relative w-full h-screen overflow-hidden">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`absolute inset-0 transition-opacity duration-1000 ease-in-out ${
              index === currentSlide ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <img
              src={slide.image}
              alt={slide.title}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center">
              <h1
                className={`text-4xl md:text-5xl font-bold text-white mb-4 transform transition-transform duration-1000 ${
                  index === currentSlide ? 'translate-x-0' : '-translate-x-full'
                }`}
              >
                {slide.title}
              </h1>
              <p
                className={`text-lg text-white transition-transform duration-1000 ${
                  index === currentSlide ? 'translate-x-0' : 'translate-x-full'
                }`}
              >
                {slide.subtitle}
              </p>
              <div className="flex space-x-4 mt-4">
                <a href="/donate" className="bg-red-500 text-white px-6 py-2 rounded transform transition-transform duration-1000 translate-y-0 hover:bg-blue-600 active:scale-95">
                  Donate
                </a>
                <a href="/about" className="bg-blue-500 text-white px-6 py-2 rounded transform transition-transform duration-1000 translate-y-0 hover:bg-green-600 active:scale-95">
                  Read More
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>

      <section className="container mx-auto py-10 px-4 z-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="p-4  flex flex-col justify-center items-center border rounded-md bg-white shadow-md">
           <LiaPeopleCarrySolid className='text-4xl text-red-500' />
            <h3 className="text-2xl font-bold">BECOME A VOLUNTEER</h3>
            <p className='mt-2 text-justify'>Help us make a difference! Join Prince Child Development Foundation to support underprivileged children through education and mentorship. Your time and skills can change lives.</p>
            <a href="/donate" className="bg-blue-700 text-white px-6 py-2 mt-4 rounded transform transition-transform duration-1000 translate-y-0 hover:bg-blue-600 active:scale-95">
                  Join Us
                </a>
          </div>
          <div className="p-4  flex flex-col justify-center items-center border rounded-md bg-white shadow-md">
           <IoEyeOutline className='text-4xl text-blue-500' />
            <h3 className="text-2xl font-bold">OUR VISION</h3>
            <p className='mt-2 text-justify'>We see an Africa where young people’s talents are fully utilised, empowering them to thrive in a competitive global economy and become catalysts for sustainable social change within their communities. </p>
            <a href="/donate" className="bg-red-500 text-white px-6 py-2 mt-4 rounded transform transition-transform duration-1000 translate-y-0 hover:bg-blue-600 active:scale-95">
                  Support Our Vission
                </a>
          </div>
          <div className="p-4  flex flex-col justify-center items-center border rounded-md bg-white shadow-md">
           <SiTransmission className='text-4xl text-blue-500' />
            <h3 className="text-2xl font-bold">OUR MISSION</h3>
            <p className='mt-2 text-justify'>To identify, nurture, and educate young African talents by providing them with professional training and facilitating opportunities for them to showcase and monetize their talents</p>
            <a href="/donate" className="bg-red-500 text-white px-6 py-2 mt-4 rounded transform transition-transform duration-1000 translate-y-0 hover:bg-blue-600 active:scale-95">
                  Be Part of Our Mission
                </a>
          </div>
        </div>
      </section>

      {/* Scrolling text section */}
      <div className="bg-black text-white text-center  py-5 mb-5 overflow-hidden relative">
        <div className="animate-marquee whitespace-nowrap">
          <h4 className='mx-4 text-6xl font-bold'>Join us to provide tools, trainings and opportunities to empower African youths in marginalized rural communities. Our approach works</h4>
        </div>
      </div>

      <div className="bg-white py-16 px-4 sm:px-6 lg:px-8">
  <div className="container mx-auto flex flex-col md:flex-row items-stretch md:space-x-8">
    {/* Left: Image */}
    <div className="w-full md:w-1/2 mb-8 md:mb-0 flex">
      <img
        src={map}
        alt="Region Covered"
        className="w-full h-full object-cover rounded-md shadow-md"
      />
    </div>
    {/* Right: Image Background with Text Overlay */}
    <div className="relative w-full md:w-1/2 h-full flex">
      <img
        src={slide2}
        alt="Our Impact"
        className="absolute top-0 left-0 w-full h-full object-cover rounded-md"
      />
      {/* Text Overlay */}
      <div className="relative z-10 p-6 md:p-8 bg-white bg-opacity-70 rounded-md text-black h-full flex flex-col justify-center">
        <h2 className="text-3xl font-bold mb-4">Our Impact</h2>
        <div className="mb-4 font-semibold text-xl">
          <p><span className='text-red-500'>2850 young individuals</span> between the ages of <span className='text-red-500'>9 and 19 </span>have utilised our talent discovery worksheet to identify both their primary and secondary talent categories. Our worksheet covers 10 talent categories, which were created after extensive research on talent and gifted education.
          </p>
        </div>
        <div className="mb-4 font-semibold text-xl">
          <p>We have extended our network across two African region; <span className='text-red-500'>East and West Africa.</span> With Kenya as our flagship entry into East and our entry into West through Nigeria and Ghana. </p>
        </div>
        <div className="mb-4 font-semibold text-xl">
          <p>We have created awareness and organized talent discovery interventions in 11 different communities is Africa.</p>
        </div>
        <div className="mb-4 font-semibold text-xl">
          <p>We also started talent development trainings in areas of art and sports for <span className='text-red-500'>100+ young people</span> in Laikipia and Kisimu county in Kenya</p>
        </div>
        <a href="/about" className="bg-blue-500 text-white px-6 py-2 mt-4 rounded transform transition-transform duration-1000 translate-y-0 hover:bg-blue-600 active:scale-95">
                  Read More
                </a>
      </div>
    </div>
  </div>
</div>

<section className="text-center">
        <h1 className="text-4xl font-bold mb-6 md:text-5xl">Our Projects</h1>
        <p className="text-2xl font-bold mb-8">We help discover and develop talents in Young Africans</p>
      </section>


<section className="grid grid-cols-1 md:grid-cols-2 my-4 lg:grid-cols-3 gap-8">
  {projects.slice(0, 3).map((project) => (
    <div key={project.id} className="p-6 border rounded-md shadow-md hover:shadow-lg transition-shadow">
      <img src={project.image} alt={project.title} className="mb-4 rounded" />
      <h2 className="text-2xl font-bold mb-2">{project.title}</h2>
      <p className="mb-4">{project.description.slice(0, 100)}...</p>
      <Link to={`/projects/${project.id}`} className="text-blue-500 hover:underline">Read More</Link>
    </div>
  ))}
</section>


<section className=" mx-auto bg-white mb-4 py-5 px-4 justify-center items-center">
        <div className="grid grid-cols-1   md:grid-cols-2   gap-8" >
          <div className="flex flex-col justify-center items-center p-6">
            <a href="/events">
              <h4 className="text-4xl font-bold mb-4 p-4 rounded-lg text-red-500 animate-blink cursor-pointer">
                Upcoming Events
              </h4>
            </a>
          </div>
          <div className="relative h-64 overflow-hidden">
            {latestEvents.map((event, index) => (
              <div
                key={index}
                className={`absolute mt-10 inset-0 transition-opacity duration-1000 ${
                  index === currentSlide ? 'opacity-100' : 'opacity-0'
                }`}
              >
                <h2 className="text-4xl font-bold mb-2">{event.type}</h2>
                <p className="text-2xl">Location: {event.location}</p>
                <p className="text-xl">Date: {event.date}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

       {/* Programs Section */}
       <section className="bg-white py-8 px-4 sm:px-6 lg:px-8">
        <div className="container mx-auto text-center mb-12">
          <h2 className="text-4xl font-bold text-black mb-4">Our Programs</h2>
          <p className="text-xl text-black">
            Empowering young talents through various initiatives aimed at development, showcasing, and continuous improvement.
          </p>
        </div>
        <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Program 1 */}
          <div className="bg-white rounded-lg shadow-md overflow-hidden transform transition-transform duration-500 hover:scale-105">
            <img src={slide1} alt="Talent Identification" className="w-full h-48 object-cover" />
            <div className="p-6">
              <h3 className="text-2xl font-semibold mb-3 text-red-600">Talent Discovery and Development (TDaD)</h3>
              <p className="text-gray-700 font-bold">
                Collaborate with local organizations to identify young talents across various fields.
              </p>
            </div>
          </div>
          {/* Program 2 */}
          <div className="bg-white rounded-lg shadow-md overflow-hidden transform transition-transform duration-500 hover:scale-105">
            <img src={slide2} alt="Training and Mentorship" className="w-full h-48 object-cover" />
            <div className="p-6">
              <h3 className="text-2xl font-semibold mb-3 text-red-600">Prince Orji Mentorship Program (POMP)
              </h3>
              <p className="text-gray-700 font-bold">
                Develop a curriculum that blends education with practical skills, supported by professional mentorship.
              </p>
            </div>
          </div>
          {/* Program 3 */}
          <div className="bg-white rounded-lg shadow-md overflow-hidden transform transition-transform duration-500 hover:scale-105">
            <img src={slide3} alt="Showcasing and Monetization" className="w-full h-48 object-cover" />
            <div className="p-6">
              <h3 className="text-2xl font-semibold mb-3 text-red-600">Education for the Underserved Program (E4U)
              </h3>
              <p className="text-gray-700 font-bold">
                Establish platforms for participants to showcase their talents, both locally and globally.
              </p>
            </div>
          </div>
          {/* Program 4 */}
          <div className="bg-white rounded-lg shadow-md overflow-hidden transform transition-transform duration-500 hover:scale-105">
            <img src={slide4} alt="Evaluation and Feedback" className="w-full h-48 object-cover" />
            <div className="p-6">
              <h3 className="text-2xl font-semibold mb-3 text-red-600">What Do You Say (WDYS)</h3>
              <p className="text-gray-700 font-bold">
                Implement continuous assessment and feedback loops to ensure program effectiveness and improvement.
              </p>
            </div>
          </div>
        </div>
        <div className="text-center mt-8">
          <a href="/donate" className="bg-red-600 text-white px-6 py-2 rounded transition-colors duration-300 hover:bg-blue-800">
            Support Our Programs
          </a>
        </div>
      </section>

        {/* Funding and Resource Mobilization Section
      <section className="bg-gradient-to-r from-red-600 to-blue-600 py-20 px-4 sm:px-6 lg:px-8">
        <div className="container mx-auto text-center mb-12 text-white">
          <h2 className="text-5xl font-bold mb-4">Funding and Resource Mobilization</h2>
          <p className="text-2xl mb-6">
            Ensuring the program's sustainability through diversified funding sources and strategic resource mobilization.
          </p>
        </div>
        <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 gap-12"> */}
          {/* Funding Source Card */}
          {/* <div className="relative bg-white rounded-lg shadow-xl transform hover:scale-105 transition-transform duration-500">
            <img src={slide1} alt="Sources of Funding" className="w-full h-56 rounded-t-lg object-cover" />
            <div className="absolute top-0 left-0 bg-black bg-opacity-40 w-full h-full rounded-t-lg"></div>
            <div className="p-8 text-center">
              <h3 className="text-3xl font-bold mb-3 text-gray-800">Sources of Funding</h3>
              <p className="text-gray-600 font-bold">
                Secure funding through grants, donations, partnerships, crowdfunding, and revenue from talent-driven initiatives.
              </p>
            </div>
          </div> */}
          {/* Resource Mobilization Card */}
          {/* <div className="relative bg-white rounded-lg shadow-xl transform hover:scale-105 transition-transform duration-500">
            <img src={slide2} alt="Resource Mobilization" className="w-full h-56 rounded-t-lg object-cover" />
            <div className="absolute top-0 left-0 bg-black bg-opacity-40 w-full h-full rounded-t-lg"></div>
            <div className="p-8 text-center">
              <h3 className="text-3xl font-bold mb-3 text-gray-800">Resource Mobilization</h3>
              <p className="text-gray-600 font-bold">
                Leverage partnerships and community engagement to provide the necessary resources for the program’s success.
              </p>
            </div>
          </div>
        </div> */}
        {/* Buttons Section */}
        {/* <div className="mt-16 text-center">
          <a
            href="/donate"
            className="inline-block bg-white text-red-600 px-8 py-4 rounded-full font-semibold shadow-md hover:bg-red-600 hover:text-white transition-all duration-300 mr-6"
          >
            Donate
          </a>
          <a
            href="/join"
            className="inline-block bg-white text-blue-600 px-8 py-4 rounded-full font-semibold shadow-md hover:bg-blue-600 hover:text-white transition-all duration-300"
          >
            Join Us
          </a>
        </div>
      </section> */}

    </main>
  );
}

export default Home;
