import React from 'react';
import HeroSection from '../components/HeroSection';
import { Link } from 'react-router-dom';
import project from '../images/project.jpg';
import projects from '../data/projectData';

function Projects({ children }) {
  return (
    <>
      <div>
        <HeroSection title="Projects" backgroundImage={project} />
      </div>
      <main className="container mx-auto py-10 px-4">
        <section className="text-center">
          <h1 className="text-4xl font-bold mb-6 md:text-5xl">Our Projects</h1>
          <p className="text-2xl mb-8">
            We help discover and develop talents in Young Africans.
          </p>
        </section>

        <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.map((project) => (
            <div key={project.id} className="p-6 border rounded-md shadow-md hover:shadow-lg transition-shadow">
              <img src={project.image} alt={project.title} className="mb-4 rounded" />
              <h2 className="text-2xl font-bold mb-2">{project.title}</h2>
              <p className="mb-4">{project.description.slice(0, 100)}...</p>
              <Link to={`/projects/${project.id}`} className="text-blue-500 hover:underline">Read More</Link>
            </div>
          ))}
        </section>
        {children}
      </main>
    </>
  );
}

export { projects };
export default Projects;
