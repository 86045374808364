import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from '@formspree/react';
import projects from '../data/projectData';

const ProjectDetail = () => {
  const { id } = useParams();
  const project = projects.find(proj => proj.id === Number(id));
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [formVisible, setFormVisible] = useState(false); // Track form visibility

  const [state, handleSubmit] = useForm("meoqaapy"); // Formspree form ID

  // Handle form submission to Formspree
  const onFormSubmit = (e) => {
    e.preventDefault();
    if (name && email) {
      setSubmitted(true);

      // Add the hidden message directly as part of the form
      const adminMessage = ` "${name}" just downloaded the file: "${project.documentLink.split('/').pop()}"`;

      // Add hidden field in the form before submission
      const form = e.target;
      const adminField = document.createElement('input');
      adminField.type = 'hidden';
      adminField.name = 'hiddenMessage';
      adminField.value = adminMessage;

      form.appendChild(adminField); // Append hidden message to form

      // Trigger Formspree submission
      handleSubmit(e);

      // Trigger file download
      if (project.documentLink && typeof project.documentLink === 'string') {
        const link = document.createElement('a');
        link.href = project.documentLink; // Ensure this is a string path
        link.download = project.documentLink.split('/').pop(); // Extracts the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        alert('Download link is not valid.');
      }
    } else {
      alert('Please fill in both fields.');
    }
  };

  const handleShowForm = () => {
    setFormVisible(true); // Show the form when the button is clicked
  };

  return (
    <div className="p-6">
      {project ? (
        <>
          <h1 className="text-4xl font-bold mb-10">{project.title}</h1>
          <div className="relative">
            <img
              src={project.image}
              alt={project.title}
              className="rounded-md w-full md:w-1/3 float-left mr-4 mb-4"
            />
            <p className="text-base text-justify">{project.description}</p>
          </div>
          <div className="mt-6">
            {/* Button to trigger form visibility */}
            {!formVisible ? (
              <button
                onClick={handleShowForm}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Click to Download Full Report
              </button>
            ) : (
              <div className="w-full md:w-1/2 ml-0">
                {/* Centered form that takes up to half the screen */}
                <form onSubmit={onFormSubmit} className="mb-4 bg-white p-4 border border-gray-300 rounded shadow-lg">
                  <label className="block mb-2">
                    Name:
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="block w-full border border-gray-300 p-2 rounded mb-2"
                      name="name"
                      required
                    />
                  </label>
                  <label className="block mb-2">
                    Email:
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="block w-full border border-gray-300 p-2 rounded mb-4"
                      name="email"
                      required
                    />
                  </label>
                  <button
                    type="submit"
                    disabled={state.submitting}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                  >
                    {state.submitting ? 'Submitting...' : 'Download'}
                  </button>
                </form>
              </div>
            )}
          </div>

          {submitted && (
            <p>Thank you, {name}! Your download of "{project.documentLink.split('/').pop()}" is successful.</p>
          )}
        </>
      ) : (
        <p>Project not found.</p>
      )}
    </div>
  );
};

export default ProjectDetail;
