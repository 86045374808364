import React, { useEffect } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import HeroSection from '../components/HeroSection';
import about from '../images/pcdflogo02.png';

function Contact() {
  const [state, handleSubmit] = useForm("meoqaapy");
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    if (state.succeeded) {
      const timer = setTimeout(() => {
        navigate('/'); // Redirect to the homepage after 3 seconds
      }, 3000);
      return () => clearTimeout(timer); // Clear timeout on component unmount
    }
  }, [state.succeeded, navigate]);

  if (state.succeeded) {
    return <p className="text-center text-xl">Thanks for your message!</p>;
  }

  return (
    <>
      <div>
        <HeroSection title="Contact Us" backgroundImage={about} />
      </div>
      <main className="container mx-auto py-10 px-4">
        <section className="text-center">
          <h1 className="text-4xl font-bold mb-6 md:text-5xl">
            Feel free to reach out to us for any inquiries or support.
          </h1>
        </section>

        <section className="max-w-lg mx-auto">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name" className="block text-lg font-medium">Full Name</label>
              <input
                type="text"
                id="name"
                name="name" 
                className="w-full p-3 border rounded-md mt-2"
                placeholder="Your Full Name"
                required
              />
            </div>

            <div>
              <label htmlFor="email" className="block text-lg font-medium">Email</label>
              <input
                id="email"
                type="email"
                name="email"
                className="w-full p-3 border rounded-md mt-2"
                placeholder="Your Email"
                required
              />
              <ValidationError 
                prefix="Email" 
                field="email"
                errors={state.errors}
              />
            </div>

            <div>
              <label htmlFor="message" className="block text-lg font-medium">Message</label>
              <textarea
                id="message"
                name="message"
                className="w-full p-3 border rounded-md mt-2"
                placeholder="Your Message"
                rows="5"
                required
              />
              <ValidationError 
                prefix="Message" 
                field="message"
                errors={state.errors}
              />
            </div>

            <button type="submit" disabled={state.submitting} className="bg-blue-500 text-white px-6 py-3 rounded-md w-full">
              Submit
            </button>
          </form>
        </section>

        <section className="mt-10 text-center">
          <h2 className="text-3xl font-bold mb-4">Our Location</h2>
          <p className="text-lg">Abuja, Nigeria</p>
          <p className="text-lg mt-2">Phone: +2349094640954</p>
        </section>
      </main>
    </>
  );
}

export default Contact;